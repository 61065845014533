import React from "react"
import { Link } from "gatsby"

import spiralsm from "../images/spiral-sm.png"

const Choices = ({choiceList}) => (
    <div className="choice-list">
        <img className="choice-spiral" src={spiralsm} alt="spiral graphic" />
    {choiceList.map(item => (
        <div className={item.choiceType.toLowerCase().replace(/ /gi, "-")} key={item.title}>
            <Link to={item.choiceType==="Success" ? "/success" : item.choiceType==="Failure" ? "/failure" : "/game/"+item.gamePage.replace(/[.,#!$%&;:{}=\-_`~()]/g,"").replace(/  /gi, "-").replace(/ /gi, "-").toLowerCase()}>{item.title}</Link>
        </div>
      ))}
    </div>
)

export default Choices