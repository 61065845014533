import React from 'react'
import { graphql } from "gatsby"
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import Choices from '../components/choices'
import Prefooter from '../components/prefooter'

export const GamePageTemplate =({
    content, title, choices
}) => (
    <div className="content">
        <h1>{title}</h1>
        <div className="page-content" dangerouslySetInnerHTML={{__html: content}} />
        <Choices choiceList={choices} />
    </div>
)

const GamePage = ({ data }) => {
    const { markdownRemark: post } = data
    const test = post.frontmatter.backgroundImage
    return (
      <Layout language={post.frontmatter.language} englishAudio={post.frontmatter.englishAudio} irishAudio={post.frontmatter.irishAudio} >
        <Helmet title={post.frontmatter.title}  bodyAttributes={{
        class: `game-page ${test}`
    }} />
        <GamePageTemplate
          title={post.frontmatter.title}
          content={post.html}
          choices={post.frontmatter.choices}
        />
        <Prefooter />
      </Layout>
    )
}

export default GamePage

export const pageQuery =  graphql`
    query GamePage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
            title
            language
            englishAudio
            irishAudio
            choices {
                title
                choiceType
                gamePage
            }
            backgroundImage
            }
        }
}
`